import {
  PAYLOAD_MAP,
} from "./constants.js";
import {
  getPartsFromCombinedValue,
  getConfigKeys,
  getNextKey,
} from "./utils.js";

export function getPayloadForArrayOfObjects(field) {
  const keys = PAYLOAD_MAP.get(field);

  const result = keys
    .map(key => this.getItemPayload({ el: this[field].model, field, key }))
    .filter(item => item);

  return result;
}

export function getPayloadForArrayOfArrays(field) {
  const keys = PAYLOAD_MAP.get(field);

  const result = this[field].model
    .map((el, index) => {
      return keys
        .map(key => this.getItemPayload({ el, field, key, index }))
        .filter(item => item);
    });

  return result;
}

export function getItemPayload({ el, field, key, index }) {
  const combinedValue = el[key];
  const { id, configId } = getPartsFromCombinedValue(combinedValue);
  const { idKey, configIdKey } = getConfigKeys(field);

  if (Array.isArray(this[field].options)) {
    return this[field].options?.[index]?.[key]
      ?.find(el => el?.[idKey] === id && el?.[configIdKey] === configId);
  }

  return this[field].options[key]
    .find(el => el[idKey] === id && el[configIdKey] === configId);
}

export function getArrayItemFromOptions(combinedValue, field, currentKey, index) {
  const { idKey, configIdKey } = getConfigKeys(field);
  const { id, configId } = getPartsFromCombinedValue(combinedValue);

  return this[field].options[index][currentKey]
    ?.find(el => el[idKey] === id && el[configIdKey] === configId) ?? {};
}

export function getObjectItemFromOptions(combinedValue, field, currentKey) {
  const { idKey, configIdKey } = getConfigKeys(field);
  const { id, configId } = getPartsFromCombinedValue(combinedValue);

  return this[field].options[currentKey]
    ?.find(el => el[idKey] === id && el[configIdKey] === configId) ?? {};
}

export function checkShowSubConfig(combinedValue, field, currentKey, index) {
  if (this.isViewMode) {
      const nextKey = getNextKey(field, currentKey);
      if (nextKey) {
        const nextItem = index
          ? this[field].options[index][nextKey] ?? {}
          : this[field].options[nextKey] ?? {};
        return Object.keys(nextItem).length > 0;
      }
  }

  return !isNaN(index)
    ? this.getArrayItemFromOptions(combinedValue, field, currentKey, index).has_child
    : this.getObjectItemFromOptions(combinedValue, field, currentKey).has_child;
}
